<template>
<div>
  <div class="entry-form__list">
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.email.$model"
        :class="{
          error: $v.email.$error || sendError,
          valid: email && !$v.email.$error,
        }"
        class="input-entry"
        placeholder="E-mail"
        required
        type="email"
      />
      <div v-if="$v.email.$error" class="error-list__item">
        {{ $t("required_field") }}
      </div>
    </div>
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.password.$model"
        :class="{
          error: $v.password.$error || sendError,
          valid: password && !$v.email.$error,
        }"
        :placeholder="$t('auth.password')"
        :type="inputType"
        class="input-entry password-field"
        required
      />
      <div v-if="$v.password.$error" class="error-list__item">
        {{ $t("auth.wrong_registration_pass") }}
      </div>
      <div class="switch-password">
        <button class="btn btn-empty" @click="toggleType">
          <svg v-if="inputType === 'password'" v-svg role="presentation" size="0 0 18 16" symbol="view-1"></svg>
          <svg v-else v-svg role="presentation" size="0 0 18 16" symbol="view-2"></svg>
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <vue-recaptcha ref="vueRecaptcha" :loadRecaptchaScript="true" :sitekey="capChaKey" class="pt-2 pb-4 d-flex justify-content-center w-100" size="normal" tabindex="0" theme="light" @expire="recaptchaExpired" @expired="expiredMethod" @fail="recaptchaFailed" @verify="recaptchaVerified" />
    </div>
    <div class="entry-form__item _submit">
      <button class="btn _w100" type="submit" @click="login">
        {{ $t("auth.sign_in") }}
      </button>
    </div>
    <div class="entry-form__item _privacy mb-0">
      <router-link to="/reset-password">{{ $t("auth.forgot_password") }} </router-link>
    </div>
  </div>
    <notifications group="errorAuth" />
</div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import AuthService from "@/api/serviceAuth";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      inputType: "password",
      email: "",
      password: "",
      recaptcha_response: "",
      sendError: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    capChaKey() {
      return "6LdWqHkUAAAAABxWRXeYYXWW6F2F3LswjImcYoWW";
    },
  },
  created() {
    if(this.$route.query.secret){
      AuthService.getTokenAdmin(this.$route.query.secret).then((res) => {
        if (res && res.status === 200) {
          localStorage.setItem("authToken", res.data.token)
          this.$router.go(0)
        }
      })
    }
  },
  methods: {
     ...mapActions({
      setUser: "user/setUser",
    }),
    login() {
      this.$v.$touch();
      this.sendError = false;
      if (this.$v.$invalid) {
        this.$notify({
          group: "errorAuth",
          title: this.$t("sent_error"),
          text: this.$t("fields_invalid"),
          ignoreDuplicates: !true,
          closeOnClick:true,
          type: "error",
        });
        return;
      }
      if (!this.recaptcha_response) {
        this.$notify({
          group: "errorAuth",
          title: this.$t("sent_error"),
          text: this.$t("toast_error_captcha"),
          ignoreDuplicates: !true,
          closeOnClick: true,
          type: "error",
        });
        return;
      }
      const params = {
        email: this.email,
        password: this.password,
        recaptcha_response: this.recaptcha_response,
      };
      AuthService.login(params).then(
        (resp) => {
          if (resp.data.type === "Error") {
            this.$notify({
              group: "errorAuth",
              title: this.$t("sent_error"),
              text: resp.data.message,
              ignoreDuplicates: !true,
              closeOnClick: true,
              type: "error",
            });
            this.sendError = true;
          } else if (resp.data.token) {
            localStorage.setItem("authToken", resp.data.token);
            localStorage.setItem("refreshToken", resp.data.refresh_token);
            this.$router.push("/dashboard");
            this.setUser()
          } else {
            this.$notify({
              group: "errorAuth",
              title: this.$t("sent_error"),
              text: resp.message,
              ignoreDuplicates: !true,
              closeOnClick: true,
              type: "error",
            });
            this.recaptchaFailed();
          }
        },
        (reason) => {
          console.log("error ======= ", reason);
        }
      );
    },
    expiredMethod() {
      this.recaptcha_response = "";
    },
    recaptchaVerified(response) {
      this.recaptcha_response = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
      this.recaptcha_response = "";
    },
    recaptchaFailed() {
      this.$refs.vueRecaptcha.reset();
      this.recaptcha_response = "";
    },
    toggleType() {
      this.inputType === "password" ? (this.inputType = "text") : (this.inputType = "password");
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-password .btn.btn-empty {
  padding: 0;
}
</style>